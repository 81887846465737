import Messages from '@core/scripts/components/messages';
import MessageBroker from '@bonprix/pattern-library/components/message-broker';
import Breakpoint from '@core/scripts/helper/breakpoint.js';

const PADDING_FOR_APP_BANNER_CLASS = '-banner-is-opened';
const APP_BANNER_EXISTS_CLASS = '-banner-exists';

const headerNode = document.querySelector('[jsh-module="header/header"]');
const enabledForBreakpoints = ['min', 'small', 'medium'];

MessageBroker.subscribe(Messages.APP_BANNER.CLOSE, onRemoveAppBanner);
MessageBroker.subscribe(Messages.WINDOW.RESIZED, checkBodyPadding);
MessageBroker.subscribe(Messages.NAVIGATION_ACCORDION.RENDERED, handleNavigationScroll);

if (document.body.classList.contains(PADDING_FOR_APP_BANNER_CLASS)) {
    window.addEventListener('scroll', handleScroll);
}

function onRemoveAppBanner() {
    document.body.classList.remove(PADDING_FOR_APP_BANNER_CLASS);
    document.body.classList.remove(APP_BANNER_EXISTS_CLASS);
    checkBodyPadding();
    window.removeEventListener('scroll', handleScroll);
}

function togglePaddingForAppBanner(scrollFactor) {
    if (scrollFactor > 20) {
        document.body.classList.remove(PADDING_FOR_APP_BANNER_CLASS);
    } else {
        document.body.classList.add(PADDING_FOR_APP_BANNER_CLASS);
    }
}

function handleScroll() {
    togglePaddingForAppBanner(window.scrollY);
}

function handleNavigationScroll() {
    document.querySelector('[jsh-first-navi-layer]')?.addEventListener('scroll', (event) => {
        togglePaddingForAppBanner(event.target.scrollTop);
        checkBodyPadding();
    })
}

function checkBodyPadding() {
    document.body.style.paddingTop = '';

    const bodyPadding = parseInt(getComputedStyle(document.body).paddingTop);
    const headerHeight = headerNode?.offsetHeight;
    const needsCorrection = headerHeight > bodyPadding + 1 || bodyPadding > headerHeight;

    if (Breakpoint.keyIsIn(enabledForBreakpoints) && needsCorrection) {
        document.body.style.paddingTop = headerHeight + 'px';
    } else {
        document.body.style.paddingTop = '';
    }
}

checkBodyPadding();
